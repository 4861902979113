import styled from "styled-components"
import {motion} from "framer-motion"
import Img from "gatsby-image"
import { Link } from "gatsby"

export const ArticleContainer = styled.div`
  margin: 20px 17px 0 17px;
  @media (min-width: 1280px) {
    margin: 60px 26px 0 26px;
  }
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
`

export const Headline = styled.h2`
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: black;
`


export const CopyText = styled.p`
    font-size: 1rem;
    line-height: 2.5rem;
    font-weight: 300;
    margin: 1rem 0 1rem 0;
    @media (min-width: 1280px) 
    {
    font-size: 1.2rem;
    width: 50%;
    align-self: flex-end;
    margin: 60px 0 60px 0;
    }
`

export const CategoryHeadline = styled.div``

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media(min-width: 1280px) {
    flex-direction: row;
    margin-bottom: 5rem;
  }
`

// need to fix sidebar

export const Navigation = styled.nav`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    flex: 20%;
    height: 100%;
  }
`

export const NavWrapper = styled.div`
    border-top: 1px solid black;
    position: fixed;
`

export const ArticleWrapper = styled.div`
  
  @media (min-width: 1024px) {
    flex: 80%;
  }
`

export const ArticleUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  
`

export const ArticleLi = styled(motion.li)`
  border: 1px solid #F1F2F2;
  border-radius: 1rem;
  cursor: pointer;
  width: 100%;
  margin-bottom: 1rem;
  padding-top: 1rem;
  @media (min-width: 1024px) {
    max-width: 45%;
    margin: 1rem;
    
  }
`

export const ArticleDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  width: 100%;
`

export const Article = styled.article``



export const FlexContainer = styled.div`
  flex: 60%;
  display: flex;
  flex-direction: column;
`

export const InnerFlexItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
`

export const FlexItem = styled.div`
  height: 100%;
  flex: 1 0 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const GatsbyImg = styled(Img)`
  width: 100%;
`

export const CategoryTitle = styled.h2`
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.5rem;
  font-weight: 700;
  color: black;
  margin-bottom: 1rem;
`

export const CustomLink = styled(Link)`
  color: #2398AB;
  padding-bottom: 1rem;
  :hover {
    color: #49CADF;
  }
`

export const Text = styled.p`
  font-size: .85rem;
  line-height: 2rem;
  margin-bottom: 1rem;
`
